'use-client';
import React from 'react';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import StylableButtonCommon from '../../StylableButtonCommon';
import type {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../StylableButton.types';
import stylableButtonSemanticClassNames from '../../../StylableButton.semanticClassNames';
import styles from './StylableButton.scss';

function getClassNames(obj: Record<string, boolean>): Array<string> {
  const keys = Object.keys(obj);
  const result = [];
  for (const key of keys) {
    if (obj[key]) {
      result.push(key);
    }
  }
  return result;
}

/**
 * This is skin for the new StylableButton, using UDP.
 * Will be the main in the future.
 */

const StylableButtonSkin: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps
> = (props: IStylableButtonProps, ref) => {
  const {
    isDisabled,
    stylableButtonClassName,
    customClassNames = [],
    corvid,
    isMaxContent = false,
    isWrapText = false,
  } = props;

  const {
    hasBackgroundColor = false,
    hasBorderColor = false,
    hasBorderRadius = false,
    hasBorderWidth = false,
    hasColor = false,
    iconSvgString,
    iconCollapsed,
  } = corvid || {};

  const semanticClassNames =
    props.semanticClassNames || stylableButtonSemanticClassNames;

  const root = [
    styles.root,
    stylableButtonClassName,
    formatClassNames(semanticClassNames.root, ...customClassNames),
    ...getClassNames({
      error: false,
      disabled: isDisabled,
      hasBackgroundColor,
      hasBorderColor,
      hasBorderRadius,
      hasBorderWidth,
      hasColor,
      isMaxContent,
      isWrapText,
    }),
  ].join(' ');

  const link = `${root} ${styles.link}`;

  const label = `
    ${styles.label}
    ${formatClassNames(semanticClassNames.buttonLabel)}
  `;

  let overrideIcon: boolean = false;
  if (!iconCollapsed && iconSvgString !== null) {
    if (iconSvgString) {
      overrideIcon = true;
    }
  }

  const icon = `
    ${styles.icon}
    ${overrideIcon ? 'override' : ''}
    ${formatClassNames(semanticClassNames.buttonIcon)}
  `;

  const extendedProps = {
    ...props,
    classNames: {
      root,
      link,
      label,
      icon,
      container: styles.container,
    },
  };

  return <StylableButtonCommon {...extendedProps} ref={ref} />;
};

export default React.forwardRef(StylableButtonSkin);
