import StylableButton_StylableButtonComponent from '../components/StylableButton/viewer/skinComps/StylableButton/StylableButton.skin';
import StylableButton_StylableButtonController from '../components/StylableButton/viewer/StylableButton.controller';


const StylableButton_StylableButton = {
  component: StylableButton_StylableButtonComponent,
  controller: StylableButton_StylableButtonController
};


export const components = {
  ['StylableButton_StylableButton']: StylableButton_StylableButton
};

